import React,{Component} from 'react';

class Foot extends Component{
  state = {

  }

  render(){
    return(
      <div className="footer footer-copyright text-center py-3">© 2021 Copyright:
        <a href="/"> MOIST.INK</a>
      </div>
    );
  }
}
export default Foot;
